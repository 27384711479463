import { render, staticRenderFns } from "./VEstimatesProjectCard.vue?vue&type=template&id=d1b88642&"
import script from "./VEstimatesProjectCard.vue?vue&type=script&lang=ts&"
export * from "./VEstimatesProjectCard.vue?vue&type=script&lang=ts&"
import style0 from "./VEstimatesProjectCard.vue?vue&type=style&index=0&id=d1b88642&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports