var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"estimates-project-card"},[(_vm.project.imageUrl)?_c('img',{staticClass:"estimates-project-card__image",attrs:{"src":_vm.project.imageUrl}}):_c('label',{staticClass:"estimates-project-card__image estimates-project-card__image--upload",attrs:{"data-upload-message":_vm.$t('page.designing.uploadImage')}},[_c('input',{ref:"input",attrs:{"disabled":_vm.isImageLoading,"accept":"image/*","hidden":"","type":"file"},on:{"change":_vm.uploadHandler}})]),_c('div',{staticClass:"estimates-project-card__main"},[_c('strong',[_vm._v(_vm._s(_vm.project.name))]),_c('span',{class:{
                'estimates-project-card__project-status--active':
                    _vm.project.status === _vm.EstimateProjectStatusEnum.active,
                'estimates-project-card__project-status--completed':
                    _vm.project.status === _vm.EstimateProjectStatusEnum.completed,
                'right': true,
            }},[_vm._v(" "+_vm._s(_vm.projectStatusName)+" ")]),_c('span',[_vm._v(_vm._s(_vm.project.estimateStage))]),_c('span',{staticClass:"right"},[_vm._v(_vm._s(_vm.$t('page.estimates.projects.code'))+": "+_vm._s(_vm.project.code))])]),_c('div',{staticClass:"estimates-project-card__documents"},_vm._l((_vm.estimateTypes),function(estimateType,index){return _c('div',{key:index,staticClass:"estimates-project-card__document"},[_c('span',{staticClass:"estimates-project-card__document-type"},[_vm._v(" "+_vm._s(estimateType.name)+" ")]),_c('div',{staticClass:"estimates-project-card__document-links"},[(estimateType.filter)?_c('div',{staticClass:"estimates-project-card__document-items",class:{
                        'estimates-project-card__document-items--estimate': estimateType.code === 'estimate',
                    }},_vm._l((_vm.estimateCardStatuses.values()),function(status){return _c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.isStatusShown(estimateType.code, status.code)),expression:"isStatusShown(estimateType.code, status.code)"}],key:status.code,staticClass:"estimates-project-card__filter",style:(`
                            color: ${status.color};
                            background: ${status.background};
                            border-color: ${status.border};
                        `),on:{"click":function($event){return _vm.filterEstimate(estimateType.code, status.code)}}},[_vm._v(" "+_vm._s(_vm.getQuantity(estimateType.filter, status.code))+" ")])}),0):_vm._e(),_c('span',{staticClass:"estimates-project-card__arrow",on:{"click":function($event){return _vm.filterEstimate(estimateType.code)}}},[_c('ArrowSVG')],1)])])}),0),_c('div',{staticClass:"estimates-project-card__statuses"},_vm._l((_vm.estimateCardStatuses.values()),function({ code, label, color }){return _c('div',{key:code,staticClass:"estimates-project-card__status"},[_c('span',{style:(`background-color: ${color}`)}),_vm._v(" "+_vm._s(label)+" ")])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }